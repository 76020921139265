import { Routes } from '@angular/router';
import { DefaultLayoutComponent } from './layout';
import { AuthGuard } from '@infra-adapters/guards/auth.guard';
import { InvalidSessionGuard } from '@infra-adapters/guards/invalidSession.guard';
import { SelectedCondoGuard } from '@infra-adapters/guards/condo.guard';
import { PeriodGuard } from '@infra-adapters/guards/period.guard';
import { UserRole } from '@core-interfaces/global';
import { RoleGuard } from '@infra-adapters/guards/role.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'condo/select',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./views/pages/condos/condo-select/condo-select.component').then(
        (m) => m.CondoSelectComponent
      ),
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard, RoleGuard, SelectedCondoGuard, PeriodGuard],
    data: { roles: [UserRole.plt, UserRole.admin], title: 'Home' },
    children: [
      {
        path: 'egresos',
        loadChildren: () =>
          import('./views/pages/Expense/routes').then((m) => m.routes),
      },
      {
        path: 'recaudacion',
        loadChildren: () =>
          import('./views/pages/Collection/routes').then((m) => m.routes),
      },
      {
        path: 'gasto-comun',
        loadChildren: () =>
          import('./views/pages/CommonExpense/routes').then((m) => m.routes),
      },
      {
        path: 'cargos',
        loadChildren: () =>
          import('./views/pages/Charge/routes').then((m) => m.routes),
      },
      {
        path: 'ingresos',
        loadChildren: () =>
          import('./views/pages/Income/routes').then((m) => m.routes),
      },
      {
        path: 'fondos',
        loadChildren: () =>
          import('./views/pages/Fund/routes').then((m) => m.routes),
      },
      {
        path: 'residentes',
        loadChildren: () =>
          import('./views/pages/Resident/routes').then((m) => m.routes),
      },
      {
        path: 'banco',
        loadChildren: () =>
          import('./views/pages/Bank/routes').then((m) => m.routes),
      },
      {
        path: 'remuneraciones',
        loadChildren: () =>
          import('./views/pages/Remunerations/routes').then((m) => m.routes),
      },
      {
        path: 'notificaciones',
        loadChildren: () =>
          import('./views/pages/Notification/routes').then((m) => m.routes),
      },
      {
        path: 'reportes-contables',
        loadChildren: () =>
          import('./views/pages/AccReports/routes').then((m) => m.routes),
      },
      {
        path: 'community',
        loadChildren: () =>
          import('./views/pages/condos/routes').then((m) => m.routes),
      },
      {
        path: 'comunidad',
        loadChildren: () =>
          import('./views/pages/Community/routes').then((m) => m.routes),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./views/pages/dashboard/routes').then((m) => m.routes),
      },
      {
        path: 'sales',
        loadChildren: () =>
          import('./views/pages/Sales/routes').then((m) => m.routes),
      },
      {
        path: 'graphics',
        loadChildren: () =>
          import('./views/pages/graphics/routes').then((m) => m.routes),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./views/pages/reports/routes').then((m) => m.routes),
      },
      {
        path: 'common-settings',
        loadChildren: () =>
          import('./views/pages/CommonSettings/routes').then((m) => m.routes),
      }
      //{
      //  path: 'users',
      //  loadChildren: () =>
      //    import('./views/pages/Community/routes').then((m) => m.routes),
      //},
      // {
      //   path: 'theme',
      //   loadChildren: () =>
      //     import('./views/theme/routes').then((m) => m.routes),
      // },
      // {
      //   path: 'base',
      //   loadChildren: () => import('./views/base/routes').then((m) => m.routes),
      // },
      // {
      //   path: 'buttons',
      //   loadChildren: () =>
      //     import('./views/buttons/routes').then((m) => m.routes),
      // },
      // {
      //   path: 'forms',
      //   loadChildren: () =>
      //     import('./views/forms/routes').then((m) => m.routes),
      // },
      // {
      //   path: 'icons',
      //   loadChildren: () =>
      //     import('./views/icons/routes').then((m) => m.routes),
      // },
      // {
      //   path: 'notifications',
      //   loadChildren: () =>
      //     import('./views/notifications/routes').then((m) => m.routes),
      // },
      // {
      //   path: 'widgets',
      //   loadChildren: () =>
      //     import('./views/widgets/routes').then((m) => m.routes),
      // },
      // {
      //   path: 'smart-table',
      //   loadChildren: () =>
      //     import('./views/smart-tables/routes').then((m) => m.routes),
      // },
      // {
      //   path: 'plugins',
      //   loadChildren: () =>
      //     import('./views/plugins/routes').then((m) => m.routes),
      // },
      // {
      //   path: 'pages',
      //   loadChildren: () =>
      //     import('./views/pages/routes').then((m) => m.routes),
      // },
      // {
      //   path: 'apps',
      //   loadChildren: () => import('./views/apps/routes').then((m) => m.routes),
      // },
    ],
  },
  {
    path: 'proveedores',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard, RoleGuard, SelectedCondoGuard, PeriodGuard],
    data: { roles: [UserRole.supplier], title: 'Proveedores' },
    children: [
      {
        path: '',
        redirectTo: 'egresos',
        pathMatch: 'full',
      },
      {
        path: 'egresos',
        loadChildren: () =>
          import('./views/pages/Suppliers/routes').then((m) => m.routes),
      },
    ],
  },
  {
    path: '404',
    loadComponent: () =>
      import('./views/pages/page404/page404.component').then(
        (m) => m.Page404Component
      ),
    data: {
      title: 'Page 404',
    },
  },
  {
    path: '500',
    loadComponent: () =>
      import('./views/pages/page500/page500.component').then(
        (m) => m.Page500Component
      ),
    data: {
      title: 'Page 500',
    },
  },
  {
    path: 'login',
    canActivate: [InvalidSessionGuard],
    loadComponent: () =>
      import('./views/pages/login/login.component').then(
        (m) => m.LoginComponent
      ),
    data: {
      title: 'Iniciar Sesión',
    },
  },
  {
    path: 'page500',
    loadComponent: () =>
      import('./views/pages/page500/page500.component').then(
        (m) => m.Page500Component
      ),
    data: {
      title: 'Error en el sistema',
    },
  },
  { path: '**', redirectTo: '404' },
];
