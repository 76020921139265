import { HttpInterceptorFn, HttpRequest, HttpHandlerFn, HttpEvent } from '@angular/common/http';
import { StorageKeys } from '@core-interfaces/global';
import { Observable } from 'rxjs';

export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const token = sessionStorage.getItem(StorageKeys.session_token);
  const condo = sessionStorage.getItem(StorageKeys.session_condo);

  if (token) {
    const clonedRequest = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        condo: condo || '',
      },
    });
    return next(clonedRequest);
  }

  return next(req);
};
