import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { NgScrollbar } from 'ngx-scrollbar';

import { IconDirective } from '@coreui/icons-angular';
import {
  ButtonCloseDirective,
  ColComponent,
  ContainerComponent,
  INavData,
  RowComponent,
  ShadowOnScrollDirective,
  SidebarBrandComponent,
  SidebarComponent,
  SidebarFooterComponent,
  SidebarHeaderComponent,
  SidebarNavComponent,
  SidebarToggleDirective,
  SidebarTogglerDirective,
  SpinnerComponent
} from '@coreui/angular-pro';

import { DefaultAsideComponent, DefaultBreadcrumbComponent, DefaultFooterComponent, DefaultHeaderComponent } from './';
import { navGeneral, navSupplier } from './_nav';
import { GlobalLoadingManager } from '@infra-adapters/services/GlobalLoading.service';
import { Observable, filter } from 'rxjs';
import { CommonModule } from '@angular/common';

import { ErrorToastComponent } from '@infra-ui/components/error-toast/error-toast.component';
import { UserRole } from '@core-interfaces/global';
import { AuthManager } from '@infra-adapters/services/Auth.service';

function isOverflown(element: HTMLElement) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  standalone: true,
  imports: [
    SidebarComponent,
    SidebarHeaderComponent,
    SidebarBrandComponent,
    RouterLink,
    IconDirective,
    NgScrollbar,
    SidebarNavComponent,
    SidebarFooterComponent,
    SidebarToggleDirective,
    SidebarTogglerDirective,
    DefaultAsideComponent,
    DefaultHeaderComponent,
    ShadowOnScrollDirective,
    ContainerComponent,
    RouterOutlet,
    DefaultFooterComponent,
    ButtonCloseDirective,
    DefaultBreadcrumbComponent,
    SpinnerComponent,

    CommonModule,

    ErrorToastComponent,
    RowComponent,
    ColComponent,
  ]
})
export class DefaultLayoutComponent implements OnInit {
  navigation: { [key: string]: INavData[] } = {
    [UserRole.admin]: navGeneral,
    [UserRole.plt]: navGeneral,
    [UserRole.worker]: navGeneral,
    [UserRole.supplier]: navSupplier,
  };
  loadingAllPage!: Observable<boolean>;

  skipBreadcrumb = false;
  skipBreadcumbRoutes = ['banco'];

  constructor(
    private authService: AuthManager,
    private globalLoadingService: GlobalLoadingManager,
    private router: Router
  ) {}

  public navItems = this.navigation[this.authService.UserSessionData()!.role];

  ngOnInit(): void {
    this.loadingAllPage = this.globalLoadingService.loading;
    this.checkRoute(this.router.url);
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(() => {
      const route = this.router.url;
      this.checkRoute(route);
    });
  }

  private checkRoute(route: string): void {
    this.skipBreadcrumb = this.skipBreadcumbRoutes.some((skipRoute) => route.includes(skipRoute));
    console.log("Skip breadcrumb: " + this.skipBreadcrumb);
  }

  onScrollbarUpdate($event: any) {
    // if ($event.verticalUsed) {
    // console.log('verticalUsed', $event.verticalUsed);
    // }
  }
}
