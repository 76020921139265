<c-dropdown alignment="end" variant="nav-item" class="header-dropdown" [autoClose]="'outside'">
    <button [caret]="false" cDropdownToggle>
      <span class="d-inline-block my-1 mx-2 position-relative">
        <svg cIcon name="cilBell" size="lg"></svg>
        <c-badge color="danger" position="top-end" shape="rounded-pill">{{
            (pushNotifications$ | async)?.length || 0
        }}</c-badge>
      </span>
    </button>
    <div cDropdownMenu [ngStyle]="{'min-width.rem': 30}">
        <div class="px-4 py-0">
            <div class="d-flex justify-content-between align-items-center">
                <h6 class="mb-0">
                    Notificaciones
                </h6>
                <button cButton color="transparent" class="p-0" (click)="markAllAsRead()">
                    <p class="text-primary mb-0">
                        Marcar como leídas
                    </p>
                </button>
            </div>
            <hr cDropdownDivider>
            <ng-container *ngIf="(pushNotifications$ | async)?.length; else noNotifications">
                <ng-container *ngTemplateOutlet="notificationsList" />
            </ng-container>
        </div>
    </div>
</c-dropdown>

<ng-template #noNotifications>
    <div class="d-flex justify-content-center align-items-center py-4 gap-2">
        <svg cIcon name="cilBell" size="xl"></svg>
        <p class="text-body-secondary text-center mb-0">
            No tienes notificaciones
        </p>
    </div>
</ng-template>
<ng-template #notificationsList>
    <div class="d-flex flex-column gap-2" style="max-height: 50vh; overflow-y: auto;">
        <ng-container *ngFor="let notification of ((pushNotifications$ | async) || [])">
            <div class="d-flex flex-column gap-1 flex-grow-1 border-bottom">
                <!-- {{ notification | json }} -->
                <p class="mb-0 fw-bold text-dark">
                    {{ notification.trigger | translatePushNotificationTrigger }}
                </p>
                <p class="mb-0 text-muted" style="font-size: 0.9rem;">
                    {{ notification.trigger | pushNotificationMessage: notification.data}}
                </p>
                <div class="d-flex justify-content-between">
                    <p class="mb-0 text-secondary fw-semibold" style="font-size: 0.8rem;">
                        {{ notification.created_at | timeAgo }}
                    </p>
                    <button cButton color="transparent" class="p-0" (click)="markAsRead(notification)">
                        <p class="text-primary mb-0" style="font-size: 0.8rem;">
                            Marcar como leída
                        </p>
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>