import { Pipe, PipeTransform } from '@angular/core';
import { EPushNotificationTrigger } from '@core-interfaces/global/PushEvents';
@Pipe({
  name: 'translatePushNotificationTrigger',
  standalone: true,
})
export class TranslatePushNotificationTriggerPipe implements PipeTransform {
  private pushNotificationTriggerMap: Record<EPushNotificationTrigger, string> = {
    [EPushNotificationTrigger.test]: 'Prueba',
  };

  transform(value: EPushNotificationTrigger): string {
    return this.pushNotificationTriggerMap[value];
  }
}