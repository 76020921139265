import { Routes } from '@angular/router';
import { DefaultLayoutComponent } from './layout';
import { AuthGuard } from '@infra-adapters/guards/auth.guard';
import { InvalidSessionGuard } from '@infra-adapters/guards/invalidSession.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home',
    },
    canActivate: [AuthGuard],
    children: [
      // {
      //   path: 'recaudaciones',
      //   loadChildren: () => import('./views/collection/routes').then((m) => m.routes)
      // },
      {
        path: 'egresos',
        loadChildren: () =>
          import('./views/Expenditures/routes').then((m) => m.routes),
      },
      {
        path: 'recaudacion',
        loadChildren: () =>
          import('./views/Income/routes').then((m) => m.routes),
      },
      {
        path: 'community',
        loadChildren: () =>
          import('./views/pages/condos/routes').then((m) => m.routes),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./views/dashboard/routes').then((m) => m.routes),
      },
      {
        path: 'theme',
        loadChildren: () =>
          import('./views/theme/routes').then((m) => m.routes),
      },
      {
        path: 'base',
        loadChildren: () => import('./views/base/routes').then((m) => m.routes),
      },
      {
        path: 'buttons',
        loadChildren: () =>
          import('./views/buttons/routes').then((m) => m.routes),
      },
      {
        path: 'forms',
        loadChildren: () =>
          import('./views/forms/routes').then((m) => m.routes),
      },
      {
        path: 'icons',
        loadChildren: () =>
          import('./views/icons/routes').then((m) => m.routes),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./views/notifications/routes').then((m) => m.routes),
      },
      {
        path: 'widgets',
        loadChildren: () =>
          import('./views/widgets/routes').then((m) => m.routes),
      },
      {
        path: 'smart-table',
        loadChildren: () =>
          import('./views/smart-tables/routes').then((m) => m.routes),
      },
      {
        path: 'plugins',
        loadChildren: () =>
          import('./views/plugins/routes').then((m) => m.routes),
      },
      {
        path: 'pages',
        loadChildren: () =>
          import('./views/pages/routes').then((m) => m.routes),
      },
      {
        path: 'apps',
        loadChildren: () => import('./views/apps/routes').then((m) => m.routes),
      },
    ],
  },
  {
    path: '404',
    loadComponent: () =>
      import('./views/pages/page404/page404.component').then(
        (m) => m.Page404Component
      ),
    data: {
      title: 'Page 404',
    },
  },
  {
    path: '500',
    loadComponent: () =>
      import('./views/pages/page500/page500.component').then(
        (m) => m.Page500Component
      ),
    data: {
      title: 'Page 500',
    },
  },
  {
    path: 'login',
    canActivate: [InvalidSessionGuard],
    loadComponent: () =>
      import('./views/pages/login/login.component').then(
        (m) => m.LoginComponent
      ),
    data: {
      title: 'Iniciar Sesión',
    },
  },
  { path: '**', redirectTo: '404' },
];
