import { Pipe, PipeTransform } from "@angular/core";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';

dayjs.extend(relativeTime);

@Pipe({
    name: 'timeAgo',
    standalone: true,
  })
  export class TimeAgoPipe implements PipeTransform {
    transform(value: string | Date): string {
      if (!value) return '';
  
      return dayjs(value).locale('es').fromNow();
    }
  }