import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BillingPeriodManager } from '@infra-adapters/services/BillingPeriod.service';


@Injectable({ providedIn: 'root' })
export class PeriodResolver {

  constructor(private billingPeriodService: BillingPeriodManager) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<void> {
    return this.billingPeriodService.getAndUpdateActivePeriods();
  }
}