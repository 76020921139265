import { inject, runInInjectionContext } from '@angular/core';
import { HttpInterceptorFn } from '@angular/common/http';
import {
  HttpRequest,
  HttpHandlerFn,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { onError } from '@apollo/client/link/error';

export const authErrorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const toastAlertService = inject(ToastrService);
  const router = inject(Router);


  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      console.log("Error");
      const errorMessage =
        error.error?.message ??
        'Error desconocido';

      toastAlertService.error(errorMessage);

      if (error.status === 401) {
        const currentUrl = router.url;
        router.navigate(['/login'], { queryParams: { returnUrl: currentUrl } });
      }
      return throwError(() => error);
    })
  );
};

export const graphqlErrorInterceptor = (
  toastrAlertService: ToastrService,
  router: Router
) => {
  return onError(({ graphQLErrors, networkError }) => {

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, extensions }) => {
        const errorMessage = message || 'Error desconocido en GraphQL';
        toastrAlertService.error(errorMessage);
        if (extensions?.['code'] === 'UNAUTHENTICATED') {
          const currentUrl = router.url;
          router.navigate(['/login'], { queryParams: { returnUrl: currentUrl } });
        }
      });
    }
  
    if (networkError) {
      console.error('Network error:', networkError);
      toastrAlertService.error('Error de red. Verifica tu conexión.');
    }
  });
}