/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';

import { AppComponent } from './infrastructure/ui/app.component';
import { appConfig } from './infrastructure/ui/app.config';

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
