import { inject } from '@angular/core';
import {
  CanActivateFn,
  Router,
  type GuardResult,
} from '@angular/router';
import { BillingPeriodManager } from '@infra-adapters/services/BillingPeriod.service';
import { CondoManager } from '@infra-adapters/services/Condo.service';

export const PeriodGuard: CanActivateFn = async (
    route,
    state
  ): Promise<GuardResult> => {
    const billingPeriodService = inject(BillingPeriodManager);
    const condoService = inject(CondoManager);
    const router = inject(Router);
    const selectedCondoId = condoService.getGlobalCondo();

    if (!selectedCondoId) {
      //si no hay condo seleccionado no obtengo los billing periods	
      return router.navigate(['/condo/select'], { queryParams: { message: 'Por favor, seleccione un condo' } });
    }

    try {
        await billingPeriodService.getAndUpdateActivePeriods();
        return true;
    } catch (error) {
        console.log(error);
        return router.navigate(['/page500'], { queryParams: { message: 'No se encontraron periodos activos' } });
    }
  };
  