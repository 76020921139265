<c-container class="m-2">
    <c-card-body>
        <h5 cCardTitle class="opacity-text">Condominio PLT</h5>
        <c-card class="p-2">
            <c-row>
                @if(currentPeriod) {
                <c-col xs="12" sm="12" md="6" lg="6">
                    <p cCardText inline class="period-text opacity-text m-0">
                        Periodo en curso: {{currentPeriod}}
                    </p>
                </c-col>
                }
                @if(collectingPeriod) {
                <c-col xs="12" sm="12" md="6" lg="6">
                    <p cCardText inline class="period-text opacity-text">
                        Periodo en recaudación: {{collectingPeriod}}
                    </p>
                </c-col>
                }
            </c-row>
        </c-card>
    </c-card-body>
</c-container>