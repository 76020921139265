<ng-container >
  <c-container [fluid]="true" class="px-4 backGroundColor">
    <!-- <button
      [cSidebarToggle]="sidebarId"
      cHeaderToggler
      class="btn d-lg-none pe-3"
      toggle="visible"
      style="margin-inline-start: -14px"
    >
      <svg cIcon name="cilMenu" size="lg"></svg>
    </button> -->
    <c-row class="align-items-center w-100 justify-content-between backGroundColor">
      <c-col xs="auto">
        <active-billing-period></active-billing-period>
      </c-col>
      <c-col xs="auto">
        <c-header-nav class="ms-0 d-flex align-items-center gap-2">
          <ng-container *ngTemplateOutlet="notifyDropdown" />
          <ng-container *ngTemplateOutlet="userDropdown" />
        </c-header-nav>
      </c-col>
    </c-row>
  </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
  <c-dropdown [popperOptions]="{ placement: 'bottom-start' }" variant="nav-item" class="header-dropdown">
    <button [caret]="false" cDropdownToggle>
      <c-avatar size="md" src="./assets/plat-logo-2.png" status="success" textColor="primary" />
    </button>
    <ul cDropdownMenu class="pt-0 w-auto">
      <li>
        <h6 cDropdownHeader class="bg-body-secondary text-body-secondary fw-semibold py-2 rounded-top">
          Cuenta
        </h6>
      </li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-1" name="cilBell"></svg>
          Alertas
          <c-badge class="ms-2 float-end" color="info-gradient"> 42</c-badge>
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="/apps/email/inbox">
          <svg cIcon class="me-1" name="cilEnvelopeOpen"></svg>
          Correos
          <c-badge class="ms-2 float-end" color="success-gradient">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-1" name="cilTask"></svg>
          Tareas
          <c-badge class="ms-2 float-end" color="danger-gradient"> 42</c-badge>
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-1" name="cilCommentSquare"></svg>
          Comentarios
          <c-badge class="ms-auto" color="warning-gradient"> 42</c-badge>
        </a>
      </li>
      <li>
        <h6 cDropdownHeader class="bg-body-secondary text-body-secondary fw-semibold py-2">
          Ajustes
        </h6>
      </li>
      <li></li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-1" name="cilUser"></svg>
          Perfil
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-1" name="cilSettings"></svg>
          Ajustes
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-1" name="cilCreditCard"></svg>
          Pagos
          <c-badge class="ms-2 float-end" color="secondary-gradient">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-1" name="cilFile"></svg>
          Proyectos
          <c-badge class="ms-2 float-end" color="primary-gradient">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <hr cDropdownDivider />
      </li>
      <!-- <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-1" name="cilLockLocked"></svg>
          Lock Account
        </a>
      </li> -->
      <li> 
        <a cDropdownItem routerLink="" (click)="logout()">
          <svg cIcon class="me-1" name="cilAccountLogout"></svg>
          Cerrar Sesion
        </a>
      </li>
    </ul>
  </c-dropdown>
</ng-template>

<ng-template #notifyDropdown>
  <notifications-panel></notifications-panel>
</ng-template>