import { inject } from '@angular/core';
import {
  CanActivateFn,
  Router,
  type MaybeAsync,
  type GuardResult,
} from '@angular/router';
import { AuthManager } from '@infra-adapters/services/Auth.service';
import { PusherNotificationService } from '@infra-adapters/services/PusherNotification.service';

export const ChannelGuard: CanActivateFn = (
  route,
  state
): MaybeAsync<GuardResult> => {
  const authService = inject(AuthManager);
  const pushService = inject(PusherNotificationService);
  const router = inject(Router);
  const currentPushChannel = authService.getPushChannel();
  if (currentPushChannel) {
    pushService.subscribeToChannel(currentPushChannel);
    return true;
  } else {
    return router.createUrlTree(['/login']);
  }
};
