<!--<c-footer>-->
  <div>
    <a href="#" target="_blank">PLT</a>
    <span> &copy; 2024</span>
  </div>
  <div class="ms-auto">
    By
    <a href="#" target="_blank">
      <span> InnovaX</span>
    </a>
  </div>
<!--</c-footer>-->
