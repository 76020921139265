import {
  type ApplicationConfig,
  importProvidersFrom,
  inject,
} from '@angular/core';

import { provideAnimations } from '@angular/platform-browser/animations';

import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withHashLocation,
  withInMemoryScrolling,
  withRouterConfig,
  withViewTransitions,
} from '@angular/router';

import { DropdownModule, SidebarModule } from '@coreui/angular-pro';
import { IconSetService } from '@coreui/icons-angular';
import { routes } from './app.routes';
import { provideApollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { provideHttpClient } from '@angular/common/http';
import { setContext } from '@apollo/client/link/context';

import { environment } from '@infra-env/environment';
import { StorageKeys } from '@core-interfaces/global';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled',
      }),
      withEnabledBlockingInitialNavigation(),
      withViewTransitions(),
      withHashLocation()
    ),
    importProvidersFrom(SidebarModule, DropdownModule),
    IconSetService,
    provideAnimations(),
    provideHttpClient(),
    provideApollo(() => {
      const httpLink = inject(HttpLink);
      const authLink = setContext((_, { headers }) => {
        const token = sessionStorage.getItem(StorageKeys.session_token);
        const condo_id = sessionStorage.getItem(StorageKeys.session_condo);

        let temp = {
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
          ...(condo_id ? { condo: condo_id } : {}),
          platform: 'WEB',
        };

        return {
          headers: {
            ...headers,
            ...temp,
          },
        };
      });
      return {
        link: ApolloLink.from([
          authLink,
          httpLink.create({ uri: environment.apiUrl }),
        ]),
        cache: new InMemoryCache({
          addTypename: false,
        }),
      };
    }),
  ],
};
