import {
  type ApplicationConfig,
  importProvidersFrom,
  inject,
  APP_INITIALIZER,
  LOCALE_ID,
} from '@angular/core';

import { provideAnimations } from '@angular/platform-browser/animations';
import { ToastrService, provideToastr } from 'ngx-toastr';
import {
  Router,
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withHashLocation,
  withInMemoryScrolling,
  withRouterConfig,
  withViewTransitions,
} from '@angular/router';

import { DropdownModule, SidebarModule } from '@coreui/angular-pro';
import { IconSetService } from '@coreui/icons-angular';
import { routes } from './app.routes';
import { provideApollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { setContext } from '@apollo/client/link/context';

import { environment } from '@infra-env/environment';
import { StorageKeys } from '@core-interfaces/global';
import { authInterceptor } from '@infra-adapters/interceptors/auth.interceptor';
import { authErrorInterceptor, graphqlErrorInterceptor } from '@infra-adapters/interceptors/authError.interceptor';

import localeEsCL from '@angular/common/locales/es-CL';
import { registerLocaleData } from '@angular/common';
import { QuillModule, provideQuillConfig } from 'ngx-quill';


registerLocaleData(localeEsCL, 'es-CL');


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled',
      }),
      withEnabledBlockingInitialNavigation(),
      withViewTransitions(),
      withHashLocation()
    ),
    provideToastr({ preventDuplicates: true, timeOut: 5000 }),
    importProvidersFrom(
      SidebarModule,
      DropdownModule,
      QuillModule 
    ),
    provideQuillConfig({
      modules: {
        syntax: false, // Enable syntax highlighting if needed
        toolbar: [
          ['bold', 'italic', 'underline'], // Text formatting buttons
          [{ header: 1 }, { header: 2 }], // Headers
          [{ list: 'ordered' }, { list: 'bullet' }], // Lists
          [{ color: [] }, { background: [] }], // Text color/background
          ['link', 'image'], // Links and images
          ['clean'], // Clear formatting
        ],
      },
      placeholder: 'Escribe aquí...', // Set global placeholder
      theme: 'snow', // Set default theme (e.g., 'snow' or 'bubble')
    }),
    IconSetService,
    provideAnimations(),
    provideHttpClient(
      withInterceptors([authInterceptor, authErrorInterceptor])
    ),
    provideApollo(() => {
      const httpLink = inject(HttpLink);
      const authLink = setContext((_, { headers }) => {
        const token = sessionStorage.getItem(StorageKeys.session_token);
        const condo_id = sessionStorage.getItem(StorageKeys.session_condo);

        let temp = {
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
          ...(condo_id ? { condo: condo_id } : {}),
          platform: 'WEB',
        };

        return {
          headers: {
            ...headers,
            ...temp,
          },
        };
      });

      const toastAlertService = inject(ToastrService);
      const router = inject(Router);
      const errorLink = graphqlErrorInterceptor(toastAlertService, router);

      return {
        link: ApolloLink.from([
          errorLink,
          authLink,
          httpLink.create({ uri: environment.apiUrl }),
        ]),
        cache: new InMemoryCache({
          addTypename: false,
        }),
      };
    }),
  ],
};
