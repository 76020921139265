import { Pipe, PipeTransform } from '@angular/core';
import { EPushNotificationTrigger } from '@core-interfaces/global/PushEvents';

@Pipe({
  name: 'pushNotificationMessage',
  standalone: true,
})
export class PushNotificationMessagePipe implements PipeTransform {
  
  private pushNotificationMessages: Record<EPushNotificationTrigger, (data: any) => string> = {
    [EPushNotificationTrigger.test]: (data) => `Prueba: Condominio: ${data.condo.name}`,
  };

  transform(trigger: EPushNotificationTrigger, data: any): string {
    return this.pushNotificationMessages[trigger]?.(data) || '';
  }
}