<!-- justify at the start -->
<c-container class="m-2 d-flex justify-content-start gap-2 backGroundColor">
    <div>
    <button cButton class="CondoButton" block (click)="navToCondoSelect()">
        <svg id="layer1" xmlns="http://www.w3.org/2000/svg" width="25.53" height="21.899" viewBox="0 0 25.53 21.899">
            <path id="path826" d="M6.493,282.174a1.823,1.823,0,0,0-1.255.552L1.594,286.37a1.824,1.824,0,0,0,0,2.581L5.239,292.6a1.825,1.825,0,1,0,2.581-2.581l-.536-.535H17.471a5.472,5.472,0,0,1,0,10.944H13.823a1.824,1.824,0,1,0,0,3.645h3.648a9.117,9.117,0,1,0,0-18.233H7.285l.535-.535a1.824,1.824,0,0,0-1.327-3.13Z" transform="translate(-1.058 -282.173)" fill="#fff"/>
          </svg>
        <h5 cCardTitle class="text-light">{{condoName}}</h5>
    </button>
    </div>
    @if(currentPeriod) {
    <div>
        <c-card>
            <c-card-body>
                <h6 cCardTitle class="m-0 fs-6">
                    Periodo en curso:
                    <span class="text-primary">
                        <strong>{{currentPeriod}}</strong>
                    </span>
                </h6>
            </c-card-body>
        </c-card>
    </div>
    }
    @if(collectingPeriod) {
    <div>
        <c-card>
            <c-card-body>
                <h6 cCardTitle class="m-0 fs-6">
                    Periodo en recaudación:
                    <span class="text-warning">
                        <strong>
                            {{collectingPeriod}}
                        </strong>
                    </span>
                </h6>
            </c-card-body>
        </c-card>
    </div>
    }
</c-container>