import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BadgeComponent, ButtonDirective, DropdownComponent, DropdownDividerDirective, DropdownHeaderDirective, DropdownItemDirective, DropdownMenuDirective, DropdownToggleDirective } from '@coreui/angular-pro';
import { IconComponent, IconDirective } from '@coreui/icons-angular';
import { PusherNotificationService } from '@infra-adapters/services/PusherNotification.service';
import { TranslatePushNotificationTriggerPipe } from '@infra-adapters/pipe/translatePushNotificationTrigger.pipe';
import { PushNotificationMessagePipe } from '@infra-adapters/pipe/pushNotificationMessage.pipe';
import { TimeAgoPipe } from '@infra-adapters/pipe/timeAgo.pipe';
import { NotificationManager } from '@infra-adapters/services/Notification.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { IPushNotification } from '@core-ports/outputs/PushNotification';

@Component({
  selector: 'notifications-panel',
  standalone: true,
  imports: [
    CommonModule,
    DropdownComponent,
    DropdownToggleDirective,
    DropdownMenuDirective,
    DropdownHeaderDirective,
    DropdownItemDirective,
    DropdownDividerDirective,
    BadgeComponent,
    IconComponent,
    IconDirective,
    ButtonDirective,
    TranslatePushNotificationTriggerPipe,
    PushNotificationMessagePipe,
    TimeAgoPipe
  ],
  templateUrl: './notifications-panel.component.html',
  styleUrl: './notifications-panel.component.scss'
})
export class NotificationsPanelComponent implements OnInit {
  private pushNotificationsSubject: BehaviorSubject<IPushNotification[]> = new BehaviorSubject<IPushNotification[]>([]);
  pushNotifications$ = this.pushNotificationsSubject.asObservable();
  loading$ = new BehaviorSubject<boolean>(true);

  constructor(
    private readonly pusherService: PusherNotificationService,
    private readonly notificationManager: NotificationManager,
  ) {}

  ngOnInit(): void {
    this.loadPushNotifications();
    this.pusherService.getChannel().bind_global((eventName: string, data: any) => {
      this.loadPushNotifications();
    });
  }


  private async loadPushNotifications() {
    this.loading$.next(true);
    const pushNotifications = await firstValueFrom(
      await this.notificationManager.getUnseenNotifications()
    );
    this.pushNotificationsSubject.next(pushNotifications);
    this.loading$.next(false);
  }

  async markAsRead(notification: IPushNotification) {
    await this.notificationManager.markNotificationsAsRead([notification.id]);
    this.loadPushNotifications();
  }

  async markAllAsRead() {
    const notifications = this.pushNotificationsSubject.value;
    await this.notificationManager.markNotificationsAsRead(notifications.map(n => n.id));
    this.loadPushNotifications();
  }


}
